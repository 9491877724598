export function isEmpty(obj) {
  if (typeof (obj) === 'undefined') return true;
  if (obj === null) return true;
  if (obj === '') return true;
  if (Array.isArray(obj) && obj.length === 0) return true;
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function isPresent(obj) {
  return !isEmpty(obj);
}
