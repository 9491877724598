import PropTypes from 'prop-types';

import OpeningsButton from '../shared/OpeningsButton';

class AnalysisOpeningsButton extends OpeningsButton {
  handleClick() {
    const { fen } = this.props;

    this.eventApi.create(fen, 'analysis_openings_click');
  }
}

AnalysisOpeningsButton.propTypes = {
  fen: PropTypes.string.isRequired,
  openings: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default AnalysisOpeningsButton;
