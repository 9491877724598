import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

class NavButtons extends React.Component {
  constructor(props) {
    super(props);
    this.getCurrent = this.getCurrent.bind(this);
    this.onClickFirst = this.onClickFirst.bind(this);
    this.onClickPrev = this.onClickPrev.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickLast = this.onClickLast.bind(this);
  }

  /* eslint-disable-next-line no-unused-vars */
  onClickFirst(_event) {
    const { first, goTo } = this.props;

    goTo(first)
  }

  /* eslint-disable-next-line no-unused-vars */
  onClickPrev(_event) {
    const { goTo } = this.props;
    const current = this.getCurrent();

    goTo(current - 1)
  }

  /* eslint-disable-next-line no-unused-vars */
  onClickNext(_event) {
    const { goTo } = this.props;
    const current = this.getCurrent();

    goTo(current + 1)
  }

  /* eslint-disable-next-line no-unused-vars */
  onClickLast(_event) {
    const { last, goTo } = this.props;

    goTo(last)
  }

  getCurrent() {
    const { last } = this.props;
    let { current } = this.props

    if(current === undefined) current = last;

    return current;
  }

  render() {
    const { first, last } = this.props;

    if(first === last) return (null); // don't render buttons because there are no moves

    const current = this.getCurrent();
    const prevDisabled = current === first;
    const nextDisabled = current === last;

    return (
      <div className="btn-group" role="group">
        <button type="button" className="btn btn-primary me-2" onClick={this.onClickFirst} disabled={prevDisabled}>
          &lt;&lt;
        </button>
        <button type="button" className="btn btn-primary me-2" onClick={this.onClickPrev} disabled={prevDisabled}>
          &lt;
        </button>
        <button type="button" className="btn btn-primary me-2" onClick={this.onClickNext} disabled={nextDisabled}>
          &gt;
        </button>
        <button type="button" className="btn btn-primary me-2" onClick={this.onClickLast} disabled={nextDisabled}>
          &gt;&gt;
        </button>
      </div>
    );
  }
}

NavButtons.propTypes = {
  dispatch: PropTypes.func.isRequired,
  current: PropTypes.number,
  first: PropTypes.number,
  last: PropTypes.number.isRequired,
  goTo: PropTypes.func.isRequired,
};

NavButtons.defaultProps = {
  first: -1,
};

function mapStateToProps(state) {
  const { navigation } = state;

  return { current: navigation.current };
}

export default connect(mapStateToProps)(NavButtons);

