import { configureStore } from '@reduxjs/toolkit'

import { analysisCastlingReducer } from '../reducers/analysis/analysisCastlingReducer';
import currentUserReducer from '../reducers/currentUserReducer';
import { analysisNameInputReducer } from '../reducers/analysis/analysisNameInputReducer';
import { analysisFenInputReducer } from '../reducers/analysis/analysisFenInputReducer';
import { analysisPositionReducer } from '../reducers/analysis/analysisPositionReducer';
import navigationReducer from '../reducers/navigationReducer';
import { analysisArrowsReducer } from '../reducers/analysis/analysisArrowsReducer';
import { analysisCalculateButtonsReducer } from '../reducers/analysis/analysisCalculateButtonsReducer';
import { analysisHeaderReducer } from '../reducers/analysis/analysisHeaderReducer';
import { analysisMoverReducer } from '../reducers/analysis/analysisMoverReducer';
import { analysisUpdateReducer } from '../reducers/analysis/analysisUpdateReducer';

export const store = configureStore({
  reducer: {
    castling: analysisCastlingReducer,
    fenInput: analysisFenInputReducer,
    nameInput: analysisNameInputReducer,
    navigation: navigationReducer,
    analysisArrows: analysisArrowsReducer,
    analysisCalculateButtons: analysisCalculateButtonsReducer,
    analysisHeader: analysisHeaderReducer,
    analysisMover: analysisMoverReducer,
    analysisUpdate: analysisUpdateReducer,
    position: analysisPositionReducer,
    currentUser: currentUserReducer,
  }
})