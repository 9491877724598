import axiosCSRF from '../util/axiosCSRF';
import { MOVE_TIME_IN_MILLISECONDS, STOCKFISH_VERSION } from '../constants/constants';
import { ANALYSIS_ACTIONS } from '../constants/actionTypes';

import { CHESSJS } from '../constants/constants';
import { isEmpty } from '../util/utils';

const { RECEIVE_ANALYSIS_MESSAGE, RECEIVE_ANALYSIS_MOVE } = ANALYSIS_ACTIONS;

export class FenApi {
  create(fen_string, uci) {
    return (dispatch) => axiosCSRF
      .post('/api/v2/fens.json', { fen: fen_string }) // check that the fen_string is valid
      .then((response) => {
        const { data } = response;
        if(data.state) { // if checkmate or stalemate just display message
          dispatch({ type: RECEIVE_ANALYSIS_MESSAGE, status: 'idle', message: data.state})
        } else if(data.verified && !isEmpty(data.analysis)) { // fen verified, use analysis from db instead of calling stockfish
          //console.log('using analysis from db');
          const { openings } = data;
          const { score, sequence } = data.analysis;
          dispatch({ type: RECEIVE_ANALYSIS_MOVE, fen: data.id, openings, score, sequence });
        } else { // fen valid, but analysis not verified, so get it using stockfish
          //console.log('using analysis from frontend');
          uci.analyze_for_time(CHESSJS.fen());
        }
      })
      .catch((error) => {
        dispatch({ type: RECEIVE_ANALYSIS_MESSAGE, status: 'error', message: error.response.data.errors})
      });
  }

  update(json) {
    const { fen, score, sequence } = json

    const data = {
      id: fen,
      fen: {
        analysis: {
          best_move: sequence[0],
          variations: [{
            score: score,
            sequence: sequence,
          }],
        },
        hardware: navigator.userAgent,
        version: STOCKFISH_VERSION,
        think_time_in_milliseconds: MOVE_TIME_IN_MILLISECONDS,
      }
    };

    axiosCSRF.put('/api/v2/fens.json', data)
      /* eslint-disable-next-line no-unused-vars */
      .then(_response => {
        // console.log('Analysis saved:', response.data);
      }).catch(error => {
        console.error('Error:', error);
      });
  }
}